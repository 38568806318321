.integrate {
    display: flex;
    justify-content: space-evenly;
}

.column {
    display: flex;
    flex-direction: column;
    padding-top: 5em;
    padding-bottom: 5em;
}

.h2 {
    font-size: 1.2em;
    color: var(--text);
}

.h1 {
    max-width: 20em;
}

.floatingTextColumn {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.floatingText {
    color: #7e7e7e;
    max-width: 40em;
    line-height: 1.5em;
}

.fallingBox {
    position: relative;
    width: 33%;
    min-width: 30em;
    max-width: 30em;
    min-height: 28em;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animateRise {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 2em;
    top: 110%;
}

.absoluteFloat {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    animation: floatUp 20s infinite linear forwards;
}

.absoluteFloat2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    animation: floatUp 20s infinite linear forwards;
}

.absoluteFloatStart {
    position: absolute;
    top: -90%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    animation: floatUp 20s linear forwards;
}

@keyframes floatUp {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-200%);
    }
}

.relative {
    position: relative;
}

@media screen and (max-width: 1200px) {
    .integrate {
        display: flex;
        flex-direction: column-reverse;
        width: 90vw;
        padding-left: 5vw;
        position: relative;
    }

    .fallingBox {
        position: absolute;
        z-index: 0;
        min-height: 100%;
        left: 0;
        height: 5em;
        width: 100%;
        z-index: 1;
        opacity: .1;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }

    .column {
        position: relative;
        z-index: 2;
    }
}