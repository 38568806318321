body {
    margin: 0;
    font-family: "Helvetica Now Display DW", sans-serif;
    overflow-x: hidden;
}

/*
light: 009FFF
dark: 1E35FF

lightopacity: 
darkopacity: 

*/

:root {
    /* Purple Theme */
    /* --primary: #6600cc;
    --primaryOpacity1: #6600ccb4;
    --primaryOpacity2: #6600cc91;
    --primaryOpacity3: #6600cca8;
    --secondary: #ff4ff3;
    --secondaryOpacity1: #ff7ff6;
    --secondaryOpacity2: #ff4ff388;
    --text: #981aec;
    --text2: #8400ff7c;

    --swatch1: #0063ff;
    --swatch2: #3381ff;
    --swatch3: #0063ff;
    
    --lightSwatch: #1eafee;
    --darkSwatch1: #002f7a;
    --darkSwatch2: #116488;
    --textSecondary: #2871e7; */

    /* Blue Theme */
    --primary: #0053be;
    --primaryOpacity1: #6600ccb4;
    --primaryOpacity2: #6600cc91;
    --primaryOpacity3: #6600cca8;
    --secondary: #00a7f5;
    --secondaryOpacity1: #ff7ff6;
    --secondaryOpacity2: #ff4ff388;
    --text: #0077ff;
    --text2: #0077ff7c;

    --darkSwatch1: #002f7a;
    --darkSwatch2: #116488;
    --swatch1: #2c8fff;

    /* Button */
    --swatch2: #1f70da;
    --swatch3: #1aa7df;

    --text3: #981aec;
    --swatch4: #6600cc;
    --swatch5: #ff4ff3;

    /* Button 2 */
    --swatch6: #007ab3;
    --swatch7: #003d8d;

    --lightSwatch: rgb(0, 238, 255);
    --textSecondary: rgb(0, 238, 255);
}

@media screen and (max-width: 648px) {
    body {
        font-size: .7em;
    }
}