.container {
    position: relative;
    overflow: hidden;
}

.fuzzy {
    position: relative;
    background: url(../assets/maps.png);
    top: 0;
    left: 0;
    width: 100%;
    height: 33em;
    background-color: rgba(0, 0, 0, 0.2);
    animation: anim 60s linear infinite;
}

.wave {
    position: absolute;
    min-width: 100em;
    width: 100%;
    height: 50px;
    bottom: -.1em;
    left: 0;
    background-size: contain;
    background-image: url(../assets/wave2.png);
    filter: drop-shadow(0 -.3em .5em #00000062);
    z-index: 1;
}

@keyframes anim {
    0% {
        background-position: 0;
    }

    100% {
        background-position: 1920px;
    }
}

/* Letters */

.header {
    text-transform: uppercase;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 5em;
    font-weight: bold;
    letter-spacing: .1em;
    color: whitesmoke;
    top: 0;
    left: 10%;
}

.lettersContainer {
    position: absolute;
    width: 100%;
    top: 1em;
    left: 0;
    font-size: 5em;
    font-weight: 700;
    color: rgba(245, 245, 245, 0.822);
    pointer-events: none;
    user-select: none;
}

.lettersList {
    position: absolute;
    width: 8em;
    top: 0;
    right: 0;
    z-index: 1;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4, 1.5em);
    text-transform: uppercase;
}

.MapLetters>i {
    position: relative;
    float: left;
    display: block;
    width: 8vw;
    height: 1.8em;
    font-weight: 700;
    font-size: 4.5rem;
    text-align: center;
    font-style: normal;
}

/* Actual content */

.content {
    padding-top: 7em;
    margin-bottom: 7em;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    z-index: 2;
    background-color: white;
}

.backImg {
    position: absolute;
    opacity: .03;
    -webkit-user-drag: none;
}

/* Column 1 */

.sectionH2 {
    font-size: 1.2em;
    color: var(--text);
}

.sectionH1 {
    max-width: 8em;
    font-size: 2.5em;
}

/* Column 2 form */

.form {
    display: flex;
    flex-direction: column;
    row-gap: 3em;
}

.row {
    display: flex;
    column-gap: 2em;
}

.text {
    color: #8f8f8f;
    max-width: 40em;
    line-height: 1.5em;
}

.label {
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: .8em;
    color: #8f8f8f;
}

.input {
    width: 14.5em;
    border: none;
    outline: none;
    margin-top: .5em;
    padding-right: 1.2em;
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: .5em;
    font-size: 1.3em;
    background-color: rgb(245, 245, 245);
    border-bottom: 1px solid rgb(197, 193, 193);
    transition: all .3s;
}

.input:focus {
    border-bottom: 1px solid rgb(71, 71, 71);
}

.textareaBottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.textArea {
    max-width: 33.8em;
    min-width: 33.8em;
    min-height: 5em;
    max-height: 15em;
    font-family: "Helvetica Now Display DW", sans-serif;
    border: none;
    outline: none;
    margin-top: .5em;
    padding-right: 1.2em;
    padding-top: .5em;
    padding-bottom: .5em;
    font-size: 1.3em;
    background-color: rgb(245, 245, 245);
    margin-bottom: .3em;
}

/* Select */

.selectBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.select {
    width: 16.8em;
    height: 2.3em;
    margin-top: .5em;
    padding-right: 1.2em;
    font-size: 1em;
    background-color: rgb(245, 245, 245);
    outline: none;
    color: black;
    border: 1px solid rgba(197, 193, 193, 0);
    cursor: pointer;
    z-index: 2;
}

.select *:first-child {
    background-color: rgb(206, 206, 206);
    color: white;
}

.select * {
    color: black;
}

.select:focus {
    border: 1px solid rgb(71, 71, 71);
}

/* Button */

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Franklin Gothic Medium';
    color: rgba(245, 245, 245, 0.993);
    background: linear-gradient(90deg, var(--secondary) 0%, var(--primary) 100%);
    letter-spacing: .1em;
    font-size: .9em;
    border-radius: 5em;
    padding: 2em;
    padding-top: .8em;
    padding-bottom: .8em;
    height: 3.2em;
    width: 14em;
    outline: none;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: relative;
    transition: all .3s;
}

.submit:hover {
    box-shadow: rgba(0, 0, 0, 0.29) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
}

.email {
    margin-bottom: .2em;
    margin-right: .4em;
    font-size: 1.2em;
    text-decoration: none;
    font-style: normal;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    z-index: 3;
}

.inputLabel {
    row-gap: .5em;
}

.fileInputBox {
    display: flex;
    align-items: center;
}

.fileText {
    font-size: .8em;
    margin-left: 2em;
}

.fileInput {
    display: none;
}

.inputLabel {
    user-select: none;
    width: 15.2em;
    border: none;
    outline: none;
    padding-right: 1.2em;
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: .5em;
    background-color: rgb(245, 245, 245);
    color: black;
    font-size: 1em;
    cursor: pointer;
    transition: all .3s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: .8em;
}

.inputLabel:active {
    background: #e9e9e9;
}

.inputFileImg {
    opacity: .8;
    width: 1.2em;
}

/* Button animation */

.submitted {
    user-select: none;
    pointer-events: none;
}

.submitBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5em;
    background: linear-gradient(90deg, #3cdb71 0%, #8ad876 100%);
    animation: fadeSuccess 2s;
}

.failedBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5em;
    background: linear-gradient(90deg, #ff5261 0%, #ff7e6a 100%);
    animation: fadeSuccess 2s;
}

@keyframes fadeSuccess {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.buttonLoading {
    filter: brightness(50%);
    user-select: none;
    pointer-events: none;
}

@media screen and (max-width: 1000px) {
    .content {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-left: 5vw;
    }

    .textArea {
        max-width: 100vw;
        min-width: 100%;
        max-width: 100%;
        min-height: 0;
    }

    .textAreaBox {
        width: 70vw;
    }

    .textareaBottom {
        width: 103%;
    }

    .lettersList {
        width: 6em;
    }
}

@media screen and (max-width: 648px) {
    .lettersContainer {
        width: 100vw;
    }

    .form {
        row-gap: 4em;
    }

    .row {
        display: flex;
        flex-direction: column;
        row-gap: 4em;
    }
}

@media screen and (max-width: 490px) {
    .lettersContainer {
        display: none;
    }
}