.container {
    width: 100%;
    height: 18em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center {
    text-align: center;
    height: 100%;
    width: 80%;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid rgb(218, 218, 218);
}

.noBorder {
    border: none;
}

.centerH2 {
    font-family: 'Trebuchet MS', sans-serif;
    text-transform: uppercase;
    font-size: 1.1em;
    color: black;
    letter-spacing: .2em;
}

.button {
    text-decoration: none;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Franklin Gothic Medium';
    color: rgba(245, 245, 245, 0.993);
    background: linear-gradient(90deg, var(--lightSwatch) 60%, var(--swatch1) 100%);
    background: linear-gradient(90deg, var(--secondary) 0%, var(--primary) 100%);
    letter-spacing: .1em;
    font-size: 1.3em;
    padding: 2em;
    padding-top: .8em;
    padding-bottom: .8em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all .3s;
}

.button:hover {
    box-shadow: rgba(0, 0, 0, 0.29) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
}